import {StringUtil} from "incomaker-react-ts-commons";

export default class TempUtil {

	static replace(str: string | null | undefined, find?: null | string, replace?: string): string {
		if (StringUtil.isEmpty(str) || StringUtil.isEmpty(find)) return '';

		// @ts-ignore
		return str.replace(find, String(replace));
	}

	static clone<T>(obj: T): T {
		if (obj === null) {
			throw new Error("Null cannot be cloned!");
		}
		if (typeof obj !== 'object') {
			throw new Error("Not an object, cannot be cloned!");
		}
		return {...obj};
	}
}
