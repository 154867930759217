import {RestClient, StringUtil} from "incomaker-react-ts-commons";
import conf from "../config/conf.json";
import {CardType} from "../types/CardType";

export class CardsRestClient extends RestClient {

	constructor() {
		super(conf.API_URL, {'Content-Type': 'application/json', 'Authorization': conf.API_KEY});
	}

	static create(): CardsRestClient {
		return new CardsRestClient()
	}

	status(): Promise<string> {
		return this.get('status').then((r) => r.text());
	}

	loadCardTypes(pluginId: bigint): Promise<Array<CardType>> {
		return this.getJson(`cards/types/${pluginId}`);
	}

	loadCardType(pluginId: bigint, suffix: string): Promise<CardType> {
		return this.getJson(`cards/types/${pluginId}/${suffix}`);
	}

	saveCardType(pluginId: bigint, ct: CardType): Promise<CardType> {
		if (StringUtil.isEmpty(ct.suffix)) {
			return this.postJson(`cards/types/${pluginId}`, ct);
		} else {
			return this.putJson(`cards/types/${pluginId}/${ct.suffix}`, ct);
		}
	}

	deleteCardType(pluginId: bigint, suffix: string): Promise<Response> {
		return this.del(`cards/types/${pluginId}/${suffix}`);
	}
}
