import React from 'react';

function Header() {
	return (
		<header>
			<h1>Věrnostní kartičky</h1>
		</header>
	);
}

export default Header;
