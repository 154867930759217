import React from 'react';
import {Spinner} from "react-bootstrap";

function Loading() {
	return (
		<div className="loading p-3">
			<Spinner animation="border" role="status">
				<span className="visually-hidden">loading</span>
			</Spinner>
		</div>
	);
}

export default Loading;
