import {BasicComponentProps} from "../../types/BasicComponentProps";
import {CardType} from "../../types/CardType";
import {Card, Stack} from "react-bootstrap";
import TempUtil from "../../util/TempUtil";
import {StringUtil} from "incomaker-react-ts-commons";

export type CardTypePreviewProps = BasicComponentProps & {
	cardType: CardType;
};

export default function CardTypePreview({cardType}: CardTypePreviewProps) {
	return (
		<div>
			<Card
				className="card-type-preview"
				style={{
					backgroundColor: cardType.settings.background_color,
					color: cardType.settings.foreground_color
				}}>
				<Card.Header>
					<Stack direction="horizontal" gap={2}>
						<img src={cardType.settings.logo_image} alt="logo" className="logo"/>
						<span>{cardType.settings.company_name}</span>
					</Stack>
				</Card.Header>
				<Card.Body>
					<h3>{cardType.settings.program_name}</h3>
					{
						cardType.settings.points_custom_col.length > 0 && (
							<Card.Text>
								<div>
									{
										cardType.settings.points_label.length > 0 ?
											TempUtil.replace(cardType.settings.points_label, '{contactName}', 'Jana Kartičková')
											: 'Počet bodů'
									}
								</div>
								<div>
									<strong>187</strong>
								</div>
							</Card.Text>
						)
					}
					{
						(!StringUtil.isEmpty(cardType.settings.barcode_type)) && (
							<div className="d-flex flex-column align-items-center">
								<div className="bg-white rounded p-1">
									<img
										src={cardType.settings.barcode_type === 'qr' ? 'https://qr.flexigent.cz?text=12345&size=100' : 'https://www.incomaker.com/sites/default/files/loyalty-cards/code-128.png'}
										className="barcode" alt="barcode"/>
								</div>
								<div>123456</div>
							</div>
						)
					}
				</Card.Body>
				<Card.Img variant="bottom" src={cardType.settings.main_image}/>
			</Card>
			<small className="p-2">
				Toto je pouze orientační náhled. Skutečná podoba kartičky se bude lišit podle platformy a použitého zažízení.
			</small>
		</div>
	)
}
